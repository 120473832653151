<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="profile-foreground position-relative mx-n4 mt-n4">
                <div class="profile-wid-bg">
                    <img src="/assets/images/profile-bg.jpg" alt="" class="profile-wid-img" />
                </div>
            </div>
            <InvestorProfileShimmer :investor="investor" />
            <div class="row mb-3">
                <div class="col-lg-12">
                    <div class="">
                        <div class="float-end">
                            <router-link :to="{ name: 'user.edit-heir' }" class="btn btn-info me-2"><i
                                    class="ri-user-line align-bottom"></i> Edit Ahliwaris
                            </router-link>
                            <router-link :to="{ name: 'user.edit-bank-account' }" class="btn btn-success me-2"><i
                                    class="ri-bank-line align-bottom"></i> Edit Akun Bank
                            </router-link>

                            <router-link :to="{ name: 'user.edit-password' }" class="btn btn-warning"><i
                                    class="ri-lock-password-line align-bottom"></i> Edit Password
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <BiodataTabComponent :investor="investor" />

        </div>
    </div>
</template>

<script>
import { endpoint, host } from "../../host";
import errorService from "../../services/error-service";
import httpService from "../../services/http-service";
import permissonService from '../../services/permisson-service';
import InvestorProfileShimmer from "../../views/investor/components/InvestorProfileShimmer.vue";
import BiodataTabComponent from "../../views/investor/components/BiodataTabComponent.vue";

export default {
    components: {
        InvestorProfileShimmer,
        BiodataTabComponent
    },
    data() {
        return {
            user: null,
            investor: null,
            actived_tab: "biodata",
        };
    },
    created() {
        this.user = permissonService.user();
        this.getInvestor();
    },
    methods: {
        async getInvestor() {
            try {
                let res = await httpService.get(endpoint.investor + `/show/${this.user['id']}`, {
                    headers: httpService.authHeader(),
                });
                this.investor = res.data.data;
                res = await httpService.get(endpoint.profile);
                this.investor = res.data.data['investor'];
            } catch (error) {
                errorService.displayError(error.response);
            }
        },

        changeActivedTab(tabName) {
            this.actived_tab = tabName;
        },
    },
};
</script>

<style>
</style>