<template>
  <div v-if="investor == null">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">Biodata</h5>
        <paragraph-shimmer :is-loading="true" :lines="10" :random-size="true" />
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">Akun Bank</h5>
        <paragraph-shimmer :is-loading="true" :lines="10" :random-size="true" />
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">Ahli Waris</h5>
        <paragraph-shimmer :is-loading="true" :lines="10" :random-size="true" />
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title mb-3">Biodata</h5>
          <div class="table-responsive">
            <table class="table table-borderless mb-0">
              <tbody>
                <tr>
                  <th class="ps-0" scope="row">NIK :</th>
                  <td class="text-muted">{{ investor["nik"] }}</td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row">Nama Lengkap :</th>
                  <td class="text-muted">
                    {{ investor.user?.name }}
                  </td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row">Nomor Telepon :</th>
                  <td class="text-muted">
                    {{ investor["phone_number"] }}
                  </td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row">E-mail :</th>
                  <td class="text-muted">
                    {{ investor.user?.email }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h5 class="card-title mb-3">Bank Account</h5>
          <div class="table-responsive">
            <table class="table table-borderless mb-0">
              <tbody>
                <tr>
                  <th class="ps-0" scope="row">Nama Bank :</th>
                  <td class="text-muted">
                    {{ investor["bank_name"] }}
                  </td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row">Nama Akun Bank :</th>
                  <td class="text-muted">
                    {{ investor["bank_account_name"] }}
                  </td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row">Nomor Akun Bank :</th>
                  <td class="text-muted">
                    {{ investor["bank_account_number"] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h5 class="card-title mb-3">Ahli Waris</h5>
          <div class="table-responsive">
            <table class="table table-borderless mb-0">
              <tbody>
                <tr>
                  <th class="ps-0" scope="row">NIK :</th>
                  <td class="text-muted">
                    {{ investor["heir_nik"] }}
                  </td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row">Nama:</th>
                  <td class="text-muted">
                    {{ investor["heir_name"] }}
                  </td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row">Nomor Telepon :</th>
                  <td class="text-muted">
                    {{ investor["heir_phone_number"] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ParagraphShimmer } from "vue3-shimmer";
export default {
  components: {
    ParagraphShimmer,
  },
  name: "BiodataTabComponent",
  props: {
    investor: { type: Object, required: true, default: null },
  },
  created(){
    // console.log(this.investor);
  }
};
</script>

<style>
</style>